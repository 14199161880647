.cls_section_details {
  padding-left: 0;
}

.MuiTabs-indicator {
  background-color: #88305f;
}

.MuiTabs-indicator {
  height: 3px !important;
}

.contractSectionContent {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.MuiCardContent-root:last-child {
  padding-bottom: 20px !important;
}

.add-btn {
  position: absolute;
  right: -60px;
}
